package com.steamstreet.vegasful.browser.account

import com.steamstreet.vegasful.browser.account.subscriptions.entitySubscribe
import com.steamstreet.vegasful.browser.account.subscriptions.myGuide
import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.html.dom.append
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.dom.*
import org.w3c.dom.url.URLSearchParams
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

typealias Renderer = suspend (Element) -> Unit


val config: dynamic by lazy {
    window["vegasful"]
}

object Account {
    private var token: Token? = null
    private val jsonClient = HttpClient(JsClient())
    private val authJson = Json {
        ignoreUnknownKeys = true
    }

    private val appScope = CoroutineScope(Dispatchers.Default)

    private val modules: Map<String, Renderer> = mapOf(
        "entity-subscribe" to ::entitySubscribe,
        "my-guide" to ::myGuide,
        "login" to { element ->
            URLSearchParams(window.location.search).let {
                element.append {
                    loginDialog("Login to your Vegasful account", "Login")
                }
            }
        },
        "post-auth" to {
            postAuth()
        }
    )

    private fun executeModule(element: Element, module: String) {
        val moduleFunction = modules[module]
        if (moduleFunction != null) {
            appScope.launch {
                moduleFunction.invoke(element)
            }
        }
    }

    private fun render() {
        document.querySelectorAll("[data-account-module]").asList().forEach { node ->
            (node as? Element)?.let { element ->
                element.getAttribute("data-account-module")?.let {
                    executeModule(element, it)
                }
            }
        }
    }

    fun run() {
        appScope.launch {
            initAuth()
            initAccountMenu()
            render()
        }
    }

    fun navigate(url: String) {
        window.location.href = url
    }

    fun initializeBaseModules() {
        (window.get("initializeModules") as? (() -> Unit))?.invoke()
    }

    suspend fun initAuth() {
        token = localStorage.get(AUTH_TOKEN_KEY)?.let {
            authJson.decodeFromString<Token>(it)
        }
        GraphQL.endPoint = config["graphQlBase"] as String
        GraphQL.tokenFetcher = {
            getToken()
        }
    }

    private fun isTokenExpired(): Boolean {
        return (token?.expiration ?: Instant.DISTANT_PAST) < Clock.System.now()
    }

    /**
     * Get the token, refreshing as necessary.
     */
    private suspend fun getToken(): String? {
        if (isTokenExpired()) {
            token?.refresh_token?.let {
                authorize(TokenType.REFRESH, it)
            }
        }
        return token?.access_token?.takeIf { !isTokenExpired() }
    }

    fun login(returnUrl: String? = null) {
        if (returnUrl != null) {
            localStorage.setItem(LOGIN_REDIRECT_KEY, returnUrl)
        }
        navigate("/login")
    }

    /**
     * Authenticate the user with the given provider through redirection.
     */
    fun authenticate(provider: String?) {
        val url = buildUrl("https://${config["loginDomain"]}/oauth2/authorize") {
            parameter("client_id", config["authClientId"])
            parameter("response_type", "code")
            parameter("redirect_uri", "${window.location.origin}/post-auth")

            if (provider != null) {
                parameter("identity_provider", provider)
            }
        }
        window.location.href = url.toString()
    }

    /**
     * Authorize using an authorization code.
     */
    suspend fun authorize(type: TokenType, code: String) {
        val tokenString = jsonClient.submitForm("https://${config["loginDomain"]}/oauth2/token",
            formParameters = Parameters.build {
                append("grant_type", type.type)
                append("client_id", config["authClientId"] as String)
                if (type == TokenType.AUTHORIZATION_CODE) {
                    append("redirect_uri", "${window.location.origin}/post-auth")
                }
                append(type.paramName, code)
            }).bodyAsText()

        token = authJson.decodeFromString<Token>(tokenString)
        token = token?.copy(
            expiration = Clock.System.now().plus((token?.expires_in ?: 0).seconds).minus(1.minutes)
        )
        localStorage.setItem(AUTH_TOKEN_KEY, authJson.encodeToString(token))
    }

    fun getLoginReturnUrl(): String? {
        return localStorage.getItem(LOGIN_REDIRECT_KEY)
    }

    fun logout() {
        localStorage.removeItem(AUTH_TOKEN_KEY)
        val url = buildUrl("https://${config["loginDomain"]}/logout") {
            parameter("client_id", config["authClientId"])
            parameter("logout_uri", "${window.location.origin}/logout")
        }
        window.location.href = url.toString()
    }

    fun isLoggedIn(): Boolean {
        return token != null
    }
}

fun buildUrl(baseUrl: String? = null, builder: URLBuilder.() -> Unit): Url =
    (baseUrl?.let { URLBuilder(it) } ?: URLBuilder()).apply(builder).build()

fun URLBuilder.parameter(key: String, value: String) {
    parameters.append(key, value)
}

fun onReady(cb: () -> Unit) {
    if (document.readyState === DocumentReadyState.COMPLETE || document.readyState === DocumentReadyState.INTERACTIVE) {
        cb()
    } else {
        document.addEventListener("DOMContentLoaded", {
            cb()
        })
    }
}


private const val LOGIN_REDIRECT_KEY = "vegasful_login_redirect"
private const val AUTH_TOKEN_KEY = "vegasful_token"

fun main() {
    onReady {
        Account.run()
    }
}

enum class TokenType(val type: String, val paramName: String) {
    REFRESH("refresh_token", "refresh_token"),
    AUTHORIZATION_CODE("authorization_code", "code")
}

@Serializable
public data class Token(
    val access_token: String,
    val refresh_token: String? = null,
    val id_token: String,
    val token_type: String,
    val expires_in: Int,
    var expiration: Instant? = null
)